<template>
    <div>
                            <!-- lead-search -->
                    <div class="lead-search-area">
                      <div class="row">

                        <div class="col-sm-6 col-md-6 col-lg-3">

                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4">
                          <div class="lead-search">
                            <form action="">
                                <div class="form-group">
                                <label for="exampleFormControlSelect1">Start</label>
                                <select class="form-control" id="exampleFormControlSelect1">
                                  <option>30 Days Ago</option>
                                  <option>Ralative Date</option>
                                  <option>Ralative Date</option>
                                </select>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3">
                          <div class="lead-search">
                            <form action="">
                                <div class="form-group">
                                <label for="exampleFormControlSelect1">End</label>
                                <select class="form-control" id="exampleFormControlSelect1">
                                  <option>Today</option>
                                  <option>Ralative Date</option>
                                  <option>Ralative Date</option>
                                </select>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-2">
                          <div class="lead-search">
                            <a class="refresh" href="#"><img class="img-fluid" src="assets/img/refresh.svg" alt=""> Refresh</a>
                          </div>
                        </div>

                      </div>
                    </div>
                    <!-- lead-search -->



                   <!-- leads-table-area  -->
                   <div class="leads-table-area attribution-table">
                    <table class="display" id="att_table">
                    <thead>
                        <tr>
                            <th>Lead Source</th>
                            <th>Leads Recived</th>
                            <th>Apps Shown</th>
                            <th>Close</th>
                            <th>Cost lead per</th>
                            <th>Car Sold</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>www.car.com</td>
                          <td>56</td>
                          <td>Norma Fox</td>
                          <td>Delaer Website</td>
                          <td>$202.87</td>
                          <td>5</td>
                        </tr>

                    </tbody>
                    </table>
                   </div>
                   <!-- leads-table-area  -->
    </div>
</template>


<script>
import $ from 'jquery'
import 'datatables.net';
import 'datatables.net-responsive';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';
export default {
    name: 'Attributions',
    created(){
    },
    watch: {
        '$route.query.type': function () {
            $('#att_table').dataTable().fnDraw();
        }
    },
    mounted(){
        $('#att_table').addClass('nowrap').dataTable({
            "responsive": true,
            "fixedHeader": true,
        });

    }
}
</script>

<style scoped>
    @import '../../assets/css/dataTables.css';
</style>