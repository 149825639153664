<template>
    <DashboardContainer>
        <Attribution/>
    </DashboardContainer>
</template>

<script>
import DashboardContainer from '../containers/DashboardContainer'
import Attribution from '../components/attribution/Attribution'

export default {
    name: 'attribution',
    beforeCreate: function () {
        var auth = this.$storage.get('auth')
        if (!auth) {
            this.$router.push('/login')
        }else{
            if(!auth.token){
                this.$router.push('/login')
            }
        }
    },
    components: {
        DashboardContainer,
        Attribution
    }
}
</script>